<template>
  <div class="News">
    <van-popup
      v-model="this.$store.state.show"
      position="right"
      :style="{ height: '100%', width: '223px' }"
      class="genduomenu"
    >
      <el-input
        suffix-icon="el-icon-search"
        class="PeSearch"
        v-model="searchText"
      >
      </el-input>
      <ul class="peMenu">
        <li @click="toHome">
          <span>首页</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toBusiness">
          <span>经营范围</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toNews">
          <span>新闻资讯</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toAdvantage">
          <span>核心优势</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toGroup_Introduction">
          <span>集团介绍</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toContact_us">
          <span>联系我们</span><i class="el-icon-arrow-right"></i>
        </li>
      </ul>
    </van-popup>
    <BusinessHeader></BusinessHeader>
    <div class="layer1" @click.stop="popHide">
      <div class="titles">
        <h2>
          {{ $t('News.NewsAndInformation') }}<span>/</span>
          <span>{{ $t('News.NewsAndInformationE') }}</span>
        </h2>
      </div>
      <el-divider></el-divider>
      <ul class="content">
        <li class="first" @click="toNewsDetails1">
          <div class="box">
            <div class="title">{{ $t('News.Title1') }}</div>
            <p class="introduce">
              {{ $t('News.Introduce1') }}
            </p>
          </div>
        </li>
        <li>
          <h4>4/10<span class="year">2021</span></h4>
          <img src="../../assets/News/240.png" alt="" />
          <div class="title">{{ $t('News.Title2') }}</div>

          <p class="info">
            {{ $t('News.Introduce2') }}
          </p>
          <span class="details" @click="toNewsDetails2"
            >{{ $t('News.ViewDetails')
            }}<img class="arrow" src="../../assets/News/路径 8.svg" alt=""
          /></span>
        </li>
        <li>
          <h4>4/08<span class="year">2021</span></h4>
          <img src="../../assets/News/242.png" alt="" />
          <div class="title">{{ $t('News.Title3') }}</div>

          <p class="info">
            {{ $t('News.Introduce2') }}
          </p>
          <span class="details" @click="toNewsDetails3"
            >{{ $t('News.ViewDetails')
            }}<img class="arrow" src="../../assets/News/路径 8.svg" alt=""
          /></span>
        </li>
        <li>
          <h4>11/24<span class="year">2020</span></h4>
          <img src="../../assets/News/244.png" alt="" />
          <div class="title">{{ $t('News.Title4') }}</div>

          <p class="info">
            {{ $t('News.Introduce2') }}
          </p>
          <span class="details" @click="toNewsDetails4"
            >{{ $t('News.ViewDetails')
            }}<img class="arrow" src="../../assets/News/路径 8.svg" alt=""
          /></span>
        </li>
        <li>
          <h4>4/01<span class="year">2021</span></h4>
          <img src="../../assets/News/246.png" alt="" />
          <div class="title">{{ $t('News.Title5') }}</div>

          <p class="info">
            {{ $t('News.Introduce2') }}
          </p>
          <span class="details" @click="toNewsDetails5"
            >{{ $t('News.ViewDetails')
            }}<img class="arrow" src="../../assets/News/路径 8.svg" alt=""
          /></span>
        </li>
        <li>
          <h4>4/07<span class="year">2021</span></h4>
          <img src="../../assets/News/248.png" alt="" />
          <div class="title">{{ $t('News.Title6') }}</div>
          <p class="info">
            {{ $t('News.Introduce2') }}
          </p>
          <span class="details" @click="toNewsDetails6"
            >{{ $t('News.ViewDetails')
            }}<img class="arrow" src="../../assets/News/路径 8.svg" alt=""
          /></span>
        </li>
        <li>
          <h4>4/08<span class="year">2021</span></h4>
          <img src="../../assets/News/250.png" alt="" />
          <div class="title">{{ $t('News.Title7') }}</div>

          <p class="info">
            {{ $t('News.Introduce2') }}
          </p>
          <span class="details" @click="toNewsDetails7"
            >{{ $t('News.ViewDetails')
            }}<img class="arrow" src="../../assets/News/路径 8.svg" alt=""
          /></span>
        </li>
        <li>
          <h4>8/06<span class="year">2020</span></h4>
          <img src="../../assets/News/252.png" alt="" />
          <div class="title">{{ $t('News.Title8') }}</div>

          <p class="info">
            {{ $t('News.Introduce2') }}
          </p>
          <span class="details" @click="toNewsDetails8"
            >{{ $t('News.ViewDetails')
            }}<img class="arrow" src="../../assets/News/路径 8.svg" alt=""
          /></span>
        </li>
      </ul>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import BusinessHeader from '../../components/BusinessHeader.vue'
import footers from '../../components/footer.vue'

export default {
  components: {
    BusinessHeader,
    footers
  },
  data () {
    return {
      searchText: ''
    }
  },
  created () {
    this.$store.commit('setShow', false)
  },
  methods: {
    toHome () {
      this.$router.push('/Home')
    },
    toNewEnergy () {
      this.$router.push('/NewEnergy')
    },
    toCar () {
      this.$router.push('/Car')
    },
    toIndustrial () {
      this.$router.push('/Industrial')
    },
    toCommunication () {
      this.$router.push('/Communication')
    },
    toInstrument () {
      this.$router.push('/Instrument')
    },
    toMotor () {
      this.$router.push('/Motor')
    },
    toRobot () {
      this.$router.push('/Robot')
    },
    toElectronic () {
      this.$router.push('/Electronic')
    },
    toNewsDetails1 () {
      this.$router.push('/NewsDetails1')
    },
    toNewsDetails2 () {
      this.$router.push('/NewsDetails2')
    },
    toNewsDetails3 () {
      this.$router.push('/NewsDetails3')
    },
    toNewsDetails4 () {
      this.$router.push('/NewsDetails4')
    },
    toNewsDetails5 () {
      this.$router.push('/NewsDetails5')
    },
    toNewsDetails6 () {
      this.$router.push('/NewsDetails6')
    },
    toNewsDetails7 () {
      this.$router.push('/NewsDetails7')
    },
    toNewsDetails8 () {
      this.$router.push('/NewsDetails8')
    },
    toNews () {
      this.$router.push('/News')
    },
    toBusiness () {
      this.$router.push('/Business')
    },
    toAdvantage () {
      this.$router.push('/Advantage')
    },
    toGroup_Introduction () {
      this.$router.push('/Group_Introduction')
    },
    toContact_us () {
      this.$router.push('/Contact_us')
    },
    popHide () {
      this.$store.commit('setShow', false)
    }
  }
}
</script>

<style lang="less" scoped>
.News {
  width: 100%;
  #header {
    width: 100%;
    height: 960px;
    background: url('../../assets/Home/458.png') no-repeat;
    background-size: cover;
    position: relative;
    .top {
      width: 1400px;
      height: 80px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .logo {
        width: 95px;
        height: 27px;
        margin-right: 30px;
      }
      .left {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          color: #fff;
          list-style: none;
          display: block;
          cursor: pointer;
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          .homeIcon {
            margin-right: 10px;
          }
        }
        .first {
          border-left: 1px solid #fff;
          padding-left: 21px;
          display: flex;
          align-items: center;
        }
      }
      .right {
        margin-left: 145px;
        width: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-input {
          width: 206px;
          height: 36px;
          .el-input__inner {
            background-color: rgba(255, 255, 255, 0.3);
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
            border: 0;
            border-radius: 25px;
            color: #fff;
          }
        }
      }
    }
  }
  .layer1 {
    width: 1678px;
    margin: 0 auto;
    .titles {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      line-height: 80px;
      padding-bottom: 60px;
      margin-top: 90px;
      h2 {
        display: block;
        width: 760px;
        font-size: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        display: flex;
      }
      span {
        font-size: 16px;
        font-family: Didot, Didot-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        &:nth-child(1) {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
    .content {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .first {
        background: url('../../assets/News/238.png');
        background-size: cover;
        width: 445px;
        position: relative;
        padding: 324px 35px 58px;
        cursor: pointer;
        .box {
          .title {
            font-size: 22px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 700;
            text-align: left;
            color: #ffffff;
            line-height: 33px;
            margin-bottom: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          .introduce {
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #ffffff;
            line-height: 33px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
      }
      li {
        width: 381px;
        height: 528px;
        list-style: none;
        margin-bottom: 60px;

        h4 {
          font-size: 40px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 20px;
          .year {
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            margin-left: 10px;
          }
        }
        .title {
          margin-top: 30px;
          font-size: 22px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .info {
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          margin-top: 20px;
          margin-bottom: 27px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        .details {
          .arrow {
            margin-left: 10px;
          }
          cursor: pointer;
        }
      }
    }
  }
  .footer {
    background: url('../../assets/Home/bottom.png');
    background-size: cover;
    height: 470px;
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .top {
      width: 1400px;
      height: 40px;
      margin: 0 auto;
      padding-top: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height: 41px;
      }
      .right {
        display: flex;
        justify-content: space-between;
        li {
          margin-left: 100px;
          display: flex;
          align-items: center;
        }
      }
    }
    .bottom {
      width: 1400px;
      height: 32px;
      margin: 0 auto;
      margin-top: 92px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .first {
        display: flex;
        align-items: center;
        .left {
          margin-right: 10px;
        }
      }
      .last {
        display: flex;
        align-items: center;
        * {
          margin-left: 30px;
        }
        span {
          font-size: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .News {
    width: 100%;
    #header {
      width: 100%;
      height: 960px;
      background: url('../../assets/Home/458.png') no-repeat;
      background-size: cover;
      position: relative;
      .top {
        width: 1400px;
        height: 80px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        .logo {
          width: 95px;
          height: 27px;
          margin-right: 30px;
        }
        .left {
          width: 1000px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          li {
            color: #fff;
            list-style: none;
            display: block;
            cursor: pointer;
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            .homeIcon {
              margin-right: 10px;
            }
          }
          .first {
            border-left: 1px solid #fff;
            padding-left: 21px;
            display: flex;
            align-items: center;
          }
        }
        .right {
          margin-left: 145px;
          width: 280px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          /deep/ .el-input {
            width: 206px;
            height: 36px;
            .el-input__inner {
              background-color: rgba(255, 255, 255, 0.3);
              -webkit-backdrop-filter: blur(2px);
              backdrop-filter: blur(2px);
              border: 0;
              border-radius: 25px;
              color: #fff;
            }
          }
        }
      }
    }
    .layer1 {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0 auto;
      .titles {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 0px;
        margin-top: 0px;
        h2 {
          display: block;
          width: 760px;
          font-size: 15px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          display: flex;
          align-items: center;
        }
        span {
          font-size: 15px;
          font-family: Didot, Didot-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          &:nth-child(1) {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
      .el-divider {
        margin-top: 10px;
        margin-bottom: 15px;
      }
      .content {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .first {
          background: url('../../assets/PE/News/311.png');
          background-size: cover;
          width: 100%;
          height: 165px;
          position: relative;
          padding: 0px 35px 58px;
          cursor: pointer;
          margin-bottom: 10px !important;
          .box {
            .title {
              font-size: 15px;
              font-family: Microsoft YaHei, Microsoft YaHei-Bold;
              font-weight: 700;
              text-align: left;
              color: #ffffff;
              line-height: 33px;
              margin-bottom: 10px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            .introduce {
              font-size: 12px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              text-align: left;
              color: #ffffff;
              line-height: 24px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }
        li {
          width: 160px;
          height: 100%;
          list-style: none;
          margin-bottom: 10px;
          img {
            width: 160px;
            height: 112.5px;
          }
          h4 {
            font-size: 20px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            margin-bottom: 20px;
            .year {
              font-size: 16px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              text-align: left;
              color: #999999;
              margin-left: 10px;
            }
          }
          .title {
            margin-top: 15px;
            font-size: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 700;
            text-align: left;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .info {
            font-size: 12px !important;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            margin-top: 10px;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .details {
            .arrow {
              margin-left: 10px;
              width: 10px;
              height: 10px;
            }
            cursor: pointer;
          }
        }
      }
    }
    .footer {
      background: url('../../assets/Home/bottom.png');
      background-size: cover;
      height: 470px;
      margin-top: 90px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .top {
        width: 1400px;
        height: 40px;
        margin: 0 auto;
        padding-top: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          height: 41px;
        }
        .right {
          display: flex;
          justify-content: space-between;
          li {
            margin-left: 100px;
            display: flex;
            align-items: center;
          }
        }
      }
      .bottom {
        width: 1400px;
        height: 32px;
        margin: 0 auto;
        margin-top: 92px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .first {
          display: flex;
          align-items: center;
          .left {
            margin-right: 10px;
          }
        }
        .last {
          display: flex;
          align-items: center;
          * {
            margin-left: 30px;
          }
          span {
            font-size: 20px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.genduomenu {
  width: 223px;
  height: 100%;
  background-color: #3333336a !important;
  backdrop-filter: blur(8px);
  // border-radius: 40px;
  position: fixed;
  right: 0;
  top: 0;
  .PeSearch {
    width: 188px;
    height: 30px;
    border-radius: 40px;
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #fff;
    .el-input__inner {
      background-color: rgba(255, 255, 255, 0.226) !important;
      color: #fff;
      border-radius: 40px;
    }
  }
  .peMenu {
    width: 100%;
    li {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #fff;
      font-size: 12px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      padding: 0 15px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
    }
  }
}
</style>
